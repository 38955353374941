import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import {
  ErrorHandlerModule,
  ErrorHandlerService,
  ErrorHandlerInterceptor,
  API_TOKEN
} from '@io-provetech/utils/error-handler';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, ErrorHandlerModule],
  providers: [
    { provide: API_TOKEN, useValue: environment.apiKey },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
